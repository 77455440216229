/* eslint-disable */
import { useGlobalContext } from "../context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TabProductDocumentation = () => {
  const { productData, setLastDownloadUrls, lastDownloadUrls, isUrlLoaded } =
    useGlobalContext();
  const { t } = useTranslation();
  const passResult = productData.PassResult;
  const linkedDocuments = passResult.LinkedDocuments;

  const availableFlagLangs = [
    "EN",
    "DE",
    "ES",
    "FR",
    "IT",
    "BR",
    "PL",
    "RU",
    "TR",
    "ZH",
    "CZ",
    "DK",
  ];
  useEffect(() => {
    if (lastDownloadUrls === null) {
      localStorage.setItem("lastDownloadUrls", JSON.stringify([]));
    }
    setLastDownloadUrls(
      JSON.parse(localStorage.getItem("lastDownloadUrls")) || []
    );
  }, [isUrlLoaded]);

  return (
    <div className="wika-tab-content">
      <Row>
        <Col lg={12}>
          {linkedDocuments.length > 0 && (
            <div>
              <div className="wika-document-links">
                <h6>{t("tabDownloads.documents")}</h6>
                <ul className="wika-flag-container-wrapper">
                  {linkedDocuments.map((data, index) => {
                    return (
                      <li key={index} style={{ listStyle: "none" }}>
                        <div className="CountryDownloadBox__container">
                          <div className="CountryDownloadBox__flagContainer">
                            <span
                              className={
                                "wika-small-flag " +
                                (availableFlagLangs.includes(data[0])
                                  ? "wika-flag-" + data[0]
                                  : "wika-flag-EN")
                              }
                            ></span>
                          </div>
                        </div>
                        <a
                          href={data[2]}
                          id=""
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                        >
                          {data[1]}

                          {/* <FaFilePdf />  */}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>{t("tabProductDocumentation.disclaimer")}</div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TabProductDocumentation;
