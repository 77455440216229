/* eslint-disable */
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../context";

const LastViewedBox = () => {
  const { t } = useTranslation();
  const { serialNumber, lastNumbers, setSerialNumber, setIsDataLoaded } =
    useGlobalContext();

  const handleClick = (number) => {
    setIsDataLoaded(false);
    setSerialNumber(number.toUpperCase());
  };

  return (
    <div>
      {/* if there's no last viewed except the current serial number, don't show the box */}
      {(lastNumbers.length > 1 ||
        (lastNumbers.length == 1 && serialNumber == "")) && (
        <div className="wika-grey-box">
          <h6 className="wika-box-title">{t("app.lastSearchNumbers")}</h6>
          <ul>
            {lastNumbers.map((number, index) => {
              // show all links except current serial number
              if (number !== serialNumber) {
                return (
                  <li key={index} style={{ listStyle: "none" }}>
                    <button
                      className="btn btn-link"
                      style={{
                        boxShadow: "none",
                        padding: 0,
                        margin: 0,
                      }}
                      onClick={() => handleClick(number)}
                    >
                      {number}
                    </button>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
export default LastViewedBox;
