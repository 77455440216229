/* eslint-disable */
import { useState } from "react";
import { useGlobalContext } from "../context";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const SearchField = (props) => {
  const { setSerialNumber, setIsDataLoaded, lastNumbers, serialNumber } =
    useGlobalContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { t } = useTranslation();
  const [errors, setError] = useState({});

  // handles changes in search bar
  const handleChange = (e) => {
    let errors = {};

    setSearchTerm(e.target.value);
    let val = e.target.value.toUpperCase();
    // only string length should be tested for
    let isMatch = val.length == 11 ? true : false;
    let isEmpty = val == "" ? true : false;
    if (isEmpty || !isMatch) {
      errors.searchbar = t("searchfield.validation");
      setIsButtonDisabled(true);
      setError(errors);
    } else {
      setIsButtonDisabled(false);
      errors.searchbar = "";
      setError(errors);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    // prevent search for current serial number
    if (searchTerm != serialNumber) {
      setIsButtonDisabled(true);
      setIsDataLoaded(false);
      setSerialNumber(searchTerm.toUpperCase());
      setSearchTerm("");
      props.handleSearch();
    }
  };

  return (
    <div className="wika-searchbar-box">
      <div className="wika-searchbar">
        <div className="wika-searchbar-shadow" id="wika-searchbar-id">
          <input
            type="text"
            className="form-control wika-searchbar-input"
            placeholder={t("searchfield.placeholder")}
            id="serialNumber"
            value={searchTerm}
            onChange={handleChange}
            list="datalistOptions"
          />
          <datalist id="datalistOptions">
            {lastNumbers.map((number, index) => {
              return <option key={index} value={number} />;
            })}
          </datalist>
        </div>
        <div className="wika-searchbar-shadow" id="wika-searchbar-btn-id">
          <button
            className="wika-button wika-searchbar-btn"
            disabled={isButtonDisabled}
            onClick={handleClick}
          >
            <FaSearch />
          </button>
        </div>
      </div>

      <div
        className={
          errors.searchbar
            ? "wika-input-validation-error"
            : "wika-input-validation-error-hidden"
        }
      >
        {errors.searchbar}
      </div>
    </div>
  );
};

export default SearchField;
