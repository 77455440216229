/* eslint-disable */
import ProductDataHeaderBox from './ProductDataHeaderBox';
import ProductDataDetails from './ProductDataDetails';
import ErrorBoundary from './ErrorBoundary';

const ProductData = () => {
  return (
    <>
      <ErrorBoundary>
        <ProductDataHeaderBox />
      </ErrorBoundary>
      <ErrorBoundary>
        <ProductDataDetails />
      </ErrorBoundary>
    </>
  );
};

export default ProductData;
