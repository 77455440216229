/* eslint-disable */
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context";
import "./i18n";
// import ErrorBoundary from './components/ErrorBoundary';
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Suspense fallback="Application is loading...">
      <AppProvider>
        <App />
      </AppProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
