/* eslint-disable */
import { useGlobalContext } from "../context";
import { useTranslation } from "react-i18next";
import ProductData from "./ProductData";

const LoadedDataSection = () => {
  const { t } = useTranslation();
  const { isDataLoaded } = useGlobalContext();

  return (
    <div>
      {isDataLoaded && (
        <div>
          {" "}
          <hr />
          <ProductData />
        </div>
      )}
    </div>
  );
};

export default LoadedDataSection;
