/* eslint-disable */
import allLangs from "../all-langs.json";
import { useGlobalContext } from "../context";
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../images", false, /\.(png|jpe?g|svg)$/)
);

const LanguageDropdown = (props) => {
  const { t } = useTranslation();
  const { language, setLanguage } = useGlobalContext();

  let options = [];

  Object.keys(allLangs).forEach(function (keyName) {
    let option = {
      value: keyName,
      label: (
        <div className="dd-lang-outer">
          <img
            src={images[allLangs[keyName].image]}
            style={{ marginRight: "0.5rem" }}
          />
          <div className="dd-lang-option">{allLangs[keyName].label}</div>
        </div>
      ),
    };
    options.push(option);
  });

  const handleClick = (e, value) => {
    e.preventDefault();
    setLanguage(value);
    localStorage.setItem("language", value);
    // sidebar closes on language change
    props.onChange();
  };

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          {options
            .filter((option) => option.value === language)
            .map((filteredLang) => (
              <Navbar.Toggle
                key="filteredLang.value"
                aria-controls="basic-navbar-nav"
                className="wika-right-dd"
              >
                <div className="wika-left-dd">{t("app.changeLang")}</div>
                <div className="wika-right-dd">{filteredLang.label}</div>
              </Navbar.Toggle>
            ))}

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {options.map((data, index) => {
                // selected entry
                return (
                  <li
                    key={index}
                    style={{ listStyle: "none" }}
                    onClick={(e) => {
                      handleClick(e, data.value);
                    }}
                  >
                    {
                      <Nav.Link
                        href="#"
                        className="wika-dd-lang-content-selected"
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                        id={data.value}
                      >
                        {" "}
                        {data.label}
                      </Nav.Link>
                    }
                  </li>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default LanguageDropdown;
