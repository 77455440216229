/* eslint-disable */
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../context";
import Col from "react-bootstrap/Col";
import SearchField from "./SearchField";

const ErrorPage = () => {
  const { t } = useTranslation();
  const { setButtonClicked, showSearchField } = useGlobalContext();

  return (
    <Col md={12} className="wika-full-page">
      {/* Search field for mobile view  */}
      <div id="wika-search-mobile">
        {showSearchField ? (
          <SearchField handleSearch={() => setButtonClicked(false)} />
        ) : (
          <div></div>
        )}
      </div>

      <div className="wika-error-text">
        <i className="fa-solid fa-triangle-exclamation fa-5x"></i>
        <h3 id="wika-error-message">{t("app.error")}</h3>
      </div>
    </Col>
  );
};

export default ErrorPage;
