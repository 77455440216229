/* eslint-disable */
import { useEffect } from "react";
import "./App.css";
import "./WIKA_bootstrap_template_v1.scss";
import { useGlobalContext } from "./context";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorBoundary from "./components/ErrorBoundary";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LastViewedBox from "./components/LastViewedBox";
import ErrorPage from "./components/ErrorPage";
import StartPage from "./components/StartPage";
import MainLayout from "./components/MainLayout";
import PrintButton from "./components/PrintButton";
import MailButton from "./components/MailButton";
import LinkToWebShop from "./components/LinkToWebShop";
// for FontAwesomePro
import "./fontawesomepro-6.0.0-web/css/all.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const {
    isLoading,
    isDataLoaded,
    isError,
    fetchData,
    setDefaultLanguage,
    setLanguage,
    serialNumber,
    language,
    setLastProducts,
    lastDownloadUrls,
    showSearchField,
    showSearchFieldFullpage,
  } = useGlobalContext();
  const { t } = useTranslation();

  useEffect(() => {
    setLanguage(setDefaultLanguage());
    localStorage.setItem("language", setDefaultLanguage());
    if (!JSON.parse(localStorage.getItem("lastDownloadUrls"))) {
      localStorage.setItem("lastDownloadUrls", JSON.stringify([]));
    }
  }, [language]); // Only re-subscribe if language changes

  useEffect(() => {
    if (serialNumber && language) {
      fetchData();
    }
  }, [language, serialNumber]);

  useEffect(() => {
    setLastProducts(JSON.parse(localStorage.getItem("lastProducts")) || []);
    if (lastDownloadUrls === null) {
      localStorage.setItem("lastDownloadUrls", JSON.stringify([]));
    }
  }, [isLoading]);

  return (
    <div className="wika-inner-wrap">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <div id="parent">
                  <Row>
                    <ErrorBoundary>
                      {isError && <ErrorPage />}

                      {/* two col layout*/}
                      {!showSearchFieldFullpage && !isError && <MainLayout />}

                      {/* full page search container*/}
                      {showSearchFieldFullpage && !isError && <StartPage />}
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <Col
                        lg
                        className={`${
                          showSearchField
                            ? "wika-main-right"
                            : "wika-main-page-stacked"
                        }`}
                      >
                        <div className="wika-right-col-button-mobile">
                          {isDataLoaded && !showSearchFieldFullpage && (
                            <ErrorBoundary>
                              <PrintButton />

                              <MailButton />
                              {/* <LinkToWebShop /> */}
                            </ErrorBoundary>
                          )}
                        </div>
                        <LastViewedBox />
                        <div className="wika-right-col-button">
                          {isDataLoaded && !showSearchFieldFullpage && (
                            <ErrorBoundary>
                              <PrintButton />

                              <MailButton />
                              {/* <LinkToWebShop /> */}
                            </ErrorBoundary>
                          )}
                        </div>
                      </Col>
                    </ErrorBoundary>
                  </Row>
                </div>
                <Row>
                  <Footer />
                </Row>
              </ErrorBoundary>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
