/* eslint-disable */
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../context";
import LanguageDropdown from "./LanguageDropdown";
import MobileSideBar from "./MobileSideBar";
import logo from "../images/logo_wika.svg";
import SearchField from "./SearchField";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const Header = () => {
  const { t } = useTranslation();
  const {
    setIsDataLoaded,
    setIsError,
    setSerialNumber,
    setLogoClicked,
    setButtonClicked,
    showSearchField,
    setLanguage,
  } = useGlobalContext();

  const handleLogoClick = (e) => {
    e.preventDefault();
    setLogoClicked(true);
    setIsDataLoaded(false);
    setIsError(false);
    setButtonClicked(true);
    setSerialNumber("");
  };
  const [searchParams, setSearchParams] = useSearchParams();

  // effects happen after render

  // react to search params
  // is executed first time page is loaded
  useEffect(() => {
    if (window.location.search) {
      let query = window.location.search;
      let searchParam = new URLSearchParams(query);
      if (searchParam.has("s")) {
        setSerialNumber(searchParam.get("s").toUpperCase());
      }
      if (searchParam.has("lang")) {
        let lang = searchParam.get("lang").split("-");
        setLanguage(lang[0]);
        localStorage.setItem("language", lang[0]);
        searchParams.delete("lang");
        setSearchParams(searchParams);
      }
      // todo: navigate to url and remove lang from url
    }
  }, []);

  return (
    <div>
      <header className="wika-header">
        <div>
          <a id="wika-site-name">WIKA Product Pass</a>
        </div>
        <LanguageDropdown />
        <div className="wika-searchbar-position">
          {showSearchField ? (
            <SearchField handleSearch={() => setButtonClicked(false)} />
          ) : (
            <div></div>
          )}
        </div>
      </header>
      <div className="wika-logo-header">
        <button className="wika-clickable-logo" onClick={handleLogoClick}>
          <img id="wika-logo-web" src={logo} alt="WikaLogo" />
        </button>
        <MobileSideBar onClick={handleLogoClick} />
      </div>
    </div>
  );
};
export default Header;
