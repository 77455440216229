/* eslint-disable */
import { useGlobalContext } from "../context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../images/logo_wika.svg";
import React from "react";

const ComponentToPrint = () => {
  const { productData, imgIsUndefined } = useGlobalContext();
  const { t } = useTranslation();
  const passResult = productData.PassResult;
  const src = passResult.PictureUrl.split("//")[1];

  useEffect(() => {
    for (let element of document.getElementsByClassName("text")) {
      element.innerHTML = passResult.ItemText;
    }
  }, [passResult.ItemText]);

  useEffect(() => {
    document.getElementById("typetext").innerHTML =
      passResult.TypeTextSecondLine;
  });
  // how to make the footer stick
  // https://github.com/gregnb/react-to-print/issues/387

  return (
    <div className="p-5">
      <table className="report-container">
        <thead className="report-header">
          <tr>
            <th className="report-header-cell">
              {/* // can be used for header */}
              <div className="header-info"></div>
            </th>
          </tr>
        </thead>
        <tfoot className="report-footer">
          <tr>
            <td className="report-footer-cell">
              <div className="footer-info">
                <div className="page-footer">
                  <div className="wika-print-grey-footer">
                    {t("printComponent.productPass")}
                  </div>
                  <div>{t("address.line_1")}</div>
                  <div>{t("address.line_2")}</div>
                  <div>{t("address.line_3")}</div>
                  <div>{t("address.line_4")}</div>
                  <img src={logo} id="wika-page-footer-logo" alt="WikaLogo" />
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
        <tbody className="report-content">
          <tr>
            <td className="report-content-cell">
              <Row>
                <Col xs={12} className="wika-print-headlines">
                  <div id="wika-blue-bookmark">
                    <div id="wika-blue-bookmark-text">
                      {t("printComponent.productPass")}
                    </div>
                  </div>
                  <h4
                    id="wika-print-headline"
                    className="wika-print-bold-headline"
                  >
                    {t("printComponent.serialNumber")}:{" "}
                    {productData.PassResult["SerialNumber"]}
                  </h4>
                  <h4 className="wika-print-bold-headline">
                    {t("printComponent.type")} {passResult.TypeText}
                  </h4>
                  <h4 className="wika-print-bold-headline">
                    {t("printComponent.itemNumber")}:{" "}
                    {productData.PassResult["ItemNumber"]}
                  </h4>

                  <h4 id="typetext"> </h4>
                  <div className="wika-print-grey-divider"></div>
                </Col>
              </Row>

              <Row>
                <Col xs={6} className=" wika-print-left-col nopadding">
                  <div className="wika-print-component">
                    <div className="wika-print-grey-headline">
                      {t("tabProductPass.productionDate")}{" "}
                    </div>
                    <ul className="wika-print-list">
                      <li key={1}>{passResult.DateManufacturing}</li>
                    </ul>

                    <div className="wika-print-grey-headline">
                      <p>{t("tabProductPass.features")}</p>
                    </div>
                    <ul className="wika-print-list">
                      {passResult.Attributes.map((attribute, index) => {
                        return (
                          <li key={index}>
                            {attribute[0]} {attribute[1]}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="wika-print-sub-headline">
                      {t("tabProductPass.description")}
                    </div>
                    {passResult.ItemText && (
                      <div>
                        <div className="text page-break"></div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={6} className="nopadding">
                  {!imgIsUndefined && (
                    <div>
                      <img
                        src={`https://portal.wika.com/serial/resource/serial/${src}`}
                        alt={passResult.TypeText}
                        style={{ width: "80%" }}
                      />
                      <div className="wika-print-pic-desc">
                        <p>{passResult.PictureDesc}</p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default ComponentToPrint;
