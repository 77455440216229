/* eslint-disable */
import { useGlobalContext } from "../context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import { Buffer } from "buffer";
import convert from "xml-js";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "./ErrorBoundary";
import { FaFilePdf } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";

const TabTestReport = () => {
  const {
    productData,
    isUrlLoading,
    setLastDownloadUrls,
    handleDownloadClick,
    lastDownloadUrls,
  } = useGlobalContext();
  const { t } = useTranslation();
  const passResult = productData.PassResult;
  const certificatesList = passResult.CertificatesList;
  // displayXmlContent() processes the XML data and displays it
  const displayXmlContent = () => {
    if (passResult.XMLData.length > 0) {
      let buffer = Buffer.from(passResult.XMLData, "base64");
      var xmlString = buffer.toString("utf8");

      var resultJS = convert.xml2js(xmlString, { ignoreDeclaration: true });

      var htmlResponse = "";

      const processElement = (curElement) => {
        if (curElement.name === "head") {
          htmlResponse +=
            "<h6>" +
            curElement.attributes.title +
            "</h6><h7>" +
            curElement.attributes.subtitle +
            "</h7>";
        }
        if (curElement.name === "separator") {
          if (curElement.attributes.type === "space") {
            htmlResponse += "<br>";
          }
          if (curElement.attributes.type === "line") {
            htmlResponse += "<hr>";
          }
        }
        if (curElement.name === "listing") {
          htmlResponse += "<table>";
          curElement.elements.map((elem) => {
            htmlResponse +=
              "<tr>" +
              "<td>" +
              elem.attributes.value_1 +
              "</td><td>" +
              elem.attributes.value_2 +
              "</td>";
            return htmlResponse;
          });
          htmlResponse += "</table>";
          return htmlResponse;
        }
        if (curElement.name === "table") {
          htmlResponse += "<table><thead>";
          curElement.elements.map((elem) => {
            if (elem.attributes.number === "1") {
              htmlResponse += "<thead><tr>";
              elem.elements.map((elemAttrib) => {
                htmlResponse +=
                  '<th style="padding: 0 1rem">' +
                  elemAttrib.attributes.value +
                  "</th>";
                return htmlResponse;
              });
              htmlResponse += "</tr>";
              return htmlResponse;
            }
            if (elem.attributes.number === "2") {
              htmlResponse += "<tr>";
              elem.elements.map((elemAttrib) => {
                htmlResponse +=
                  '<th style="padding: 0 1rem; border-bottom:1px solid gray">' +
                  elemAttrib.attributes.value +
                  "</th>";
                return htmlResponse;
              });
              htmlResponse += "</tr>";
              return htmlResponse;
            }
            return htmlResponse;
          });
          htmlResponse += "</thead><tbody>";
          curElement.elements.map((elem) => {
            if (
              elem.attributes.number !== "1" &&
              elem.attributes.number !== "2"
            ) {
              htmlResponse += "<tr>";
              elem.elements.map((elemAttrib) => {
                htmlResponse +=
                  '<td style="padding: 0 1rem">' +
                  elemAttrib.attributes.value +
                  "</td>";
                return htmlResponse;
              });
              htmlResponse += "</tr>";
              return htmlResponse;
            }
            return htmlResponse;
          });
          htmlResponse += "</tbody></table>";
          return htmlResponse;
        }

        if (curElement.type === "element" && curElement.elements) {
          var childElements = curElement.elements;

          childElements.forEach((childElement) => {
            processElement(childElement);
          });
        }
        return htmlResponse;
      };

      const htmlResult = processElement(resultJS.elements[0]);
      return htmlResult;
    }
  };

  useEffect(() => {
    var elements = document.getElementsByClassName("xmlcontent");

    Array.prototype.forEach.call(elements, function (el) {
      el.innerHTML = displayXmlContent();
    });
  }, [passResult.XMLData]);
  //-------------------------------------------------------------------

  // handleXmlClick() displays the XML data in a new tab
  const handleXmlClick = () => {
    let buffer = Buffer.from(passResult.XMLData, "base64");
    var xmlString = buffer.toString("utf8");

    let blob = new Blob([xmlString], { type: "text/xml" });
    let url = URL.createObjectURL(blob);
    window.open(url);
    URL.revokeObjectURL(url);
  };
  //-------------------------------------------------------------------

  // this block eighter gets the downloadUrl from local storage if it already exists
  // or fetches a download url from the API
  useEffect(() => {
    setLastDownloadUrls(
      JSON.parse(localStorage.getItem("lastDownloadUrls")),
      []
    );
  }, [isUrlLoading]);

  const measuringReport = passResult.CertificatesList.find(
    (element) => element[2] === "certificate_ProdData"
  );

  return (
    <div className="wika-tab-content">
      <Row>
        {/* otherwise displays 'undefined' if xml doesn't return anything */}
        {passResult.XMLData !== "" && <Col sm={8} className="xmlcontent"></Col>}
        <Col sm={4} className="col-sm-4" style={{ align: "center" }}>
          {passResult.XMLData !== "" ||
            (measuringReport && (
              <h6 className="wika-sub-headline-right">
                {t("tabTestReport.showTestReport")}
              </h6>
            ))}
          {passResult.XMLData !== "" && (
            <button
              className="wika-link"
              style={{
                boxShadow: "none",
                margin: 0,
                textDecoration: "none",
              }}
              onClick={handleXmlClick}
            >
              <FaFileCode /> {t("tabTestReport.xml")}
            </button>
          )}
          {measuringReport &&
            (lastDownloadUrls &&
            lastDownloadUrls.find(
              (element) => element.certNumber === measuringReport[1]
            ) ? (
              <a
                className="wika-link"
                style={{
                  boxShadow: "none",
                  margin: 0,
                  textDecoration: "none",
                }}
                href={
                  lastDownloadUrls.find(
                    (element) => element.certNumber === measuringReport[1]
                  ).url
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFilePdf /> {measuringReport[0]} {t("tabTestReport.viewPdf")}
              </a>
            ) : (
              <button
                id="wika-link-tabtestreport"
                className="wika-link"
                style={{
                  boxShadow: "none",
                  margin: 0,
                }}
                onClick={() => handleDownloadClick(measuringReport[1])}
              >
                <FaFilePdf /> {t("tabTestReport.pdf")}
              </button>
            ))}

          {/* )} */}
          <div className="wika-cert-block">
            {certificatesList.length > 0 && (
              <div>
                <h6>{t("tabDownloads.certificates")}</h6>
                {isUrlLoading && (
                  <div
                    style={{
                      margin: "auto",
                      paddingTop: "6px",
                    }}
                  >
                    {t("tabDownloads.loading")}...
                    <div
                      className="spinner-border spinner-border-sm wika-cert-loading"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}

                {certificatesList.map((data, index) => {
                  if (
                    lastDownloadUrls &&
                    lastDownloadUrls.find(
                      (element) => element.certNumber === data[1]
                    )
                  ) {
                    return (
                      <div key={index}>
                        <li
                          key={index}
                          className="wika-link"
                          style={{ listStyle: "none" }}
                        >
                          <a
                            href={
                              lastDownloadUrls.find(
                                (element) => element.certNumber === data[1]
                              ).url
                            }
                            key={index}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFilePdf /> {data[0]}
                          </a>
                        </li>
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      <li key={index} style={{ listStyle: "none" }}>
                        <button
                          className="btn btn-link wika-link"
                          key={index}
                          style={{
                            textDecoration: "none",
                            boxShadow: "none",
                            margin: 0,
                          }}
                          onClick={() => handleDownloadClick(data[1])}
                        >
                          {t("tabDownloads.downloadButton")} {data[0]}
                        </button>
                      </li>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TabTestReport;
