import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import React, { useContext } from 'react';
import { AccordionContext } from 'react-bootstrap';

function CustomAccordionToggle({ children, eventKey, callback }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        callback && callback(eventKey),
    );
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            type="button"
            className="wika-accordion-btn"
            onClick={decoratedOnClick}
        >
            <div><i className={isCurrentEventKey ? 'fa-solid fa-minus wika-accordion-toggle fa-2xs' : 'fa-solid fa-plus wika-accordion-toggle fa-2xs'}></i></div>
            {children}
        </button>
    );
}
export default CustomAccordionToggle;