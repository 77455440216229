/* eslint-disable */
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DateTime, Interval } from "luxon";
import allLangs from "../src/all-langs.json";

const AppContext = React.createContext();

// variables/states and functions (can be used in all components)
const AppProvider = ({ children }) => {
  const [serialNumber, setSerialNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [productData, setProductData] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [language, setLanguage] = useState("");
  const [wasLogoClicked, setLogoClicked] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(true);
  const [queryString, setQueryString] = useState("");
  const [imgIsUndefined, SetImgUndefined] = useState(false);
  const [hasDatasheet, setHasDatasheet] = useState(false);
  const [dropdown, setDropdown] = useState("");

  const showSearchField = !isButtonClicked || isLoading || isDataLoaded;
  const showSearchFieldFullpage = !isDataLoaded && !isLoading && !isError;

  const onImgError = () => {
    SetImgUndefined(true);
  };

  // lastNumbers is an array where the last serial numbers are stored
  // lastNumbers is updated by setLastSerialNumbers()
  const lastSerialNumbersLength = 10;

  const [lastNumbers, setLastNumbers] = useState(
    JSON.parse(localStorage.getItem("lastNumbers")) || []
  );

  const setLastSerialNumbers = () => {
    if (!isError) {
      if (!lastNumbers.includes(serialNumber)) {
        if (lastNumbers.length < lastSerialNumbersLength) {
          lastNumbers.unshift(serialNumber);
        } else {
          lastNumbers.pop();
          lastNumbers.unshift(serialNumber);
        }
      } else {
        let foundIndex = lastNumbers.findIndex(
          (element) => element === serialNumber
        );
        lastNumbers.splice(foundIndex, 1);
        lastNumbers.unshift(serialNumber);
      }
      localStorage.setItem("lastNumbers", JSON.stringify(lastNumbers));
    }
  };
  //-------------------------------------------------------------------

  // lastProducts is an array where the last products are stored and updated by storeProducts()
  const lastProductsLength = 10;

  const [lastProducts, setLastProducts] = useState(
    JSON.parse(localStorage.getItem("lastProducts")) || []
  );

  const storeProducts = (lang, sNumber, prod) => {
    if (!isError) {
      let tempProduct = {
        language: lang,
        serial: sNumber,
        product: prod,
        timeStamp: DateTime.now(),
      };
      lastProducts.push(tempProduct);

      if (lastProducts.length > lastProductsLength) {
        lastProducts.shift();
      }

      localStorage.setItem("lastProducts", JSON.stringify(lastProducts));
    }
  };

  /* getProductFromStorage() checks whether there is a matching product already stored in lastProducts
  if a matching product exists, it is returned: lastProducts[foundIndex] 
  else undefined is returned */
  const daysSinceLastProductFetch = 10;

  const getProductFromStorage = (lang, sNumber) => {
    let now = DateTime.now();
    let foundIndex = lastProducts.findIndex(
      (element) => element.language === lang && element.serial === sNumber
    );
    if (foundIndex === -1) {
      return undefined;
    } else {
      if (
        Interval.fromDateTimes(
          DateTime.fromISO(lastProducts[foundIndex].timeStamp),
          now
        ).length("days") <= daysSinceLastProductFetch
      ) {
        return lastProducts[foundIndex];
      } else {
        lastProducts.splice(foundIndex, 1);
        return undefined;
      }
    }
  };
  //-------------------------------------------------------------------

  //fetchData() eighter loads product data from local storage or fetches data from API

  const fetchData = () => {
    setIsError(false);
    setIsLoading(true);

    let item = getProductFromStorage(language, serialNumber);
    let daysBetween = 0;
    let newTimestamp = new Date();

    // calculate how many days have passed since data was requested
    if (item !== undefined) {
      daysBetween = Math.floor(
        Math.abs(newTimestamp - new Date(item.timeStamp)) /
          (1000 * 60 * 60 * 24)
      );
    }

    // get item from storage in case it's there and it's not older than a day
    if (item !== undefined && daysBetween < 1) {
      setProductData(item.product);
      setIsLoading(false);
      setIsError(false);
      setIsDataLoaded(true);
      setLastSerialNumbers();
      setHasDatasheet(item.product.PassResult.HasDatasheet);
      return;
    }
    // send request if it's not in storage
    const url = `https://portal.wika.com/serial/srv/serialreq.aspx?obj=productpass&serial=${serialNumber}&reqapp=web.ExtSerial.wika.com&reqver=0.4a&format=json&lng=${language}`;
    fetch(url)
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          return resp.json();
        } else {
          if (!resp.ok) {
            setIsLoading(false);
            setIsError(true);
            // setting it to empty so it can't be added to last viewed list
            setSerialNumber("");
            setHasDatasheet(false);
            throw new Error(resp.statusText);
          }
        }
      })
      .then((product) => {
        if (product.Status == "ERROR") {
          setIsLoading(false);
          setIsError(true);
          // setting it to empty so it can't be added to last viewed list
          setSerialNumber("");
          setHasDatasheet(false);
        } else {
          setProductData(product);
          if (product.Status === "OK") {
            storeProducts(language, serialNumber, product);
            setIsDataLoaded(true);
            setIsError(false);
            setHasDatasheet(product.PassResult.HasDatasheet);
            setLastSerialNumbers();
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsError(true);
        setHasDatasheet(false);
        setIsLoading(false);
        // setting it to empty so it can't be added to last viewed list
        setSerialNumber("");
      });
  };
  //-------------------------------------------------------------------

  // handleDownloadClick() eighter gets downloadUrl from API and stores it in local storage
  // or retrieves it from local storage
  const lastDownloadUrlsLength = 50;
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [isUrlLoaded, setIsUrlLoaded] = useState(false);
  const [lastDownloadUrls, setLastDownloadUrls] = useState(
    JSON.parse(localStorage.getItem("lastDownloadUrls")) || []
  );

  const storeDownloadUrls = (certificate, downloadUrl) => {
    if (lastDownloadUrls === null) {
      localStorage.setItem("lastDownloadUrls", JSON.stringify([]));
    }
    let tempObj = { certNumber: certificate, url: downloadUrl };
    setLastDownloadUrls(JSON.parse(localStorage.getItem("lastDownloadUrls")));
    lastDownloadUrls.push(tempObj);

    if (lastDownloadUrls.length >= lastDownloadUrlsLength) {
      lastDownloadUrls.shift();
    }

    localStorage.setItem("lastDownloadUrls", JSON.stringify(lastDownloadUrls));
  };

  const getDownloadUrlFromStorage = (certificate) => {
    if (lastDownloadUrls) {
      let found = lastDownloadUrls.find(
        (element) => element.certNumber === certificate
      );
      return found;
    }

    return undefined;
  };

  const handleDownloadClick = async (certificateId) => {
    setIsUrlLoading(true);
    var timerStop = 0;

    let item = getDownloadUrlFromStorage(certificateId);
    if (item !== undefined) {
      setIsUrlLoading(false);
      setIsUrlLoaded(true);
      return;
    }

    const getDownloadId = async function () {
      const url = `https://portal.wika.com/serial/srv/serialreq.aspx?obj=dlinit&certid=${certificateId}&serial=${serialNumber}&reqapp=web.ExtSerial.wika.com&reqver=0.4a&format=json&lng=en`;

      const response = await fetch(url);
      if (!response.ok) {
        setIsError(true);
        setIsUrlLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const certData = await response.json();
      return certData.DownloadId;
    };
    const downloadId = await getDownloadId();

    const getDownloadLink = async function () {
      if (downloadId === undefined) {
        return;
      }
      const url = `https://portal.wika.com/serial/srv/serialreq.aspx?obj=dllink&downloadid=${downloadId}&serial=${serialNumber}&reqapp=web.ExtSerial.wika.com&reqver=0.4a&format=json&lng=en`;

      const response = await fetch(url);
      if (!response.ok) {
        setIsError(true);
        setIsUrlLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const downloadInfo = await response.json();

      if (
        downloadInfo.Status === "ERROR" &&
        downloadInfo.ErrorCode === "#DL_PENDING"
      ) {
        const getDownloadData = async function (timerId) {
          const response = await fetch(url);
          if (!response.ok) {
            setIsError(true);
            setIsUrlLoading(false);
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const downloadInfo = await response.json();
          if (downloadInfo.Status === "OK" && downloadInfo.DownloadId !== "") {
            clearInterval(timerId);
            const certtificateList = downloadInfo.CertificateList[0];
            const url = `https://portal.wika.com/serial/download/certificates/${certtificateList}`;
            storeDownloadUrls(certificateId, url);
            setIsUrlLoading(false);
            setIsUrlLoaded(true);
          }
        };

        // 5 min are 300 seconds
        const fetchTimer = async () => {
          const timerId = setInterval(async () => {
            timerStop = timerStop + 2000;
            if (timerStop > 300000) {
              setIsUrlLoading(false);
              setIsError(true);
              clearInterval(timerId);
              return;
            }
            return await getDownloadData(timerId);
          }, 2000);
        };
        fetchTimer();
      }
    };
    await getDownloadLink();
  };
  //-------------------------------------------------------------------

  /* Function sets default language for the app (called in App.js);
      if there is already a language setting in local storage,
      this will be the chosen language.
      If the app is used for the first time, the language will
      be read from the browser / UI settings and stored in local
      storage (with English as fallback language). */
  const { i18n } = useTranslation();

  const setDefaultLanguage = () => {
    if (localStorage.getItem("language")) {
      i18n.changeLanguage(localStorage.getItem("language"));
      return localStorage.getItem("language");
    } else {
      // firstly, check five character language
      const defLanguageLong = (
        navigator.language ? navigator.language : navigator.userLanguage
      ).toLowerCase();

      if (defLanguageLong in allLangs) {
        i18n.changeLanguage(defLanguageLong);
        return defLanguageLong;
      }
      // check if shortened version exists
      const defLanguageShort = (
        navigator.language ? navigator.language : navigator.userLanguage
      )
        .substring(0, 2)
        .toLowerCase();

      if (defLanguageShort in allLangs) {
        i18n.changeLanguage(defLanguageShort);
        return defLanguageShort;
      }

      // fallback is english
      i18n.changeLanguage("en");
      return "en";
    }
  };

  //-------------------------------------------------------------------

  //return contains all variables/states and functions that are exported to other components
  return (
    <AppContext.Provider
      value={{
        serialNumber,
        setSerialNumber,
        isLoading,
        setIsLoading,
        isError,
        setIsError,
        productData,
        setProductData,
        isDataLoaded,
        setIsDataLoaded,
        fetchData,
        language,
        setLanguage,
        setDefaultLanguage,
        setLastSerialNumbers,
        lastNumbers,
        setLastProducts,
        isUrlLoading,
        setLastDownloadUrls,
        handleDownloadClick,
        lastDownloadUrls,
        isUrlLoaded,
        setIsUrlLoading,
        wasLogoClicked,
        setLogoClicked,
        isButtonClicked,
        setButtonClicked,
        queryString,
        setQueryString,
        showSearchField,
        showSearchFieldFullpage,
        imgIsUndefined,
        SetImgUndefined,
        onImgError,
        hasDatasheet,
        setHasDatasheet,
        dropdown,
        setDropdown,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

export const useGlobalContext = () => {
  return useContext(AppContext);
};
