/* only visible on mobiles */

import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../images/logo_wika.svg";
import { useState } from "react";
import MobileLanguageDropdown from "./MobileLanguageDropdown";
import { useTranslation } from "react-i18next";

const MobileSideBar = (props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="wika-sidebar-mobile">
      <div className="wika-logo-site-name-mobile">
        <button onClick={props.onClick} className="wika-clickable-logo-mobile">
          <img src={logo} alt="WikaLogo" id="wika-logo-mobile" />
        </button>
        <div id="wika-site-name-mobile">WIKA Product Pass</div>
      </div>

      <div id="wika-sidebar-button-mobile">
        <Button
          className="wika-dd-burger"
          variant="primary"
          onClick={handleShow}
        >
          <i className="fa-solid fa-bars fa-3x "></i>
        </Button>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("app.settings")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <MobileLanguageDropdown onChange={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
export default MobileSideBar;
