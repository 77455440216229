/* eslint-disable */

import ComponentToPrint from "./ComponentToPrint";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";

const PrintButton = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { t } = useTranslation();

  return (
    <div id="wika-print-btn" className="wika-button-shadow">
      <button className="wika-button-orange" onClick={handlePrint}>
        {t("app.printButton")}
      </button>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <ComponentToPrint />
        </div>
      </div>
    </div>
  );
};

export default PrintButton;
