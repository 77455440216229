/* eslint-disable */
import { useGlobalContext } from "../context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

const TabDownloads = () => {
  const { productData } = useGlobalContext();
  const { t } = useTranslation();

  const passResult = productData.PassResult;
  const linkedWebReferences = passResult.LinkedWebReferences;

  return (
    <div className="wika-tab-content">
      <Row>
        <Col lg={6}>
          {linkedWebReferences.length > 0 && (
            <div>
              <h6>{t("tabDownloads.webReferences")}</h6>
              {linkedWebReferences.map((data, index) => {
                return (
                  <div key={index}>
                    <li key={index} style={{ listStyle: "none" }}>
                      <a
                        href={data[2]}
                        id=""
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                      >
                        {data[1]} ({data[0]})
                      </a>
                    </li>
                  </div>
                );
              })}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TabDownloads;
