/* eslint-disable */
import { useGlobalContext } from "../context";
import Col from "react-bootstrap/Col";
import LoadedDataSection from "./LoadedDataSection";
import LoadingSection from "./LoadingSection";
import SearchField from "./SearchField";

const MainLayout = () => {
  const { showSearchField, isButtonClicked, handleSearch, setButtonClicked } =
    useGlobalContext();

  return (
    <Col md={10} className="wika-main-left">
      <div className="wika-col-spacing">
        {/* Search field for mobile view  */}
        <div id="wika-search-mobile">
          {showSearchField ? (
            <SearchField handleSearch={() => setButtonClicked(false)} />
          ) : (
            <div></div>
          )}
        </div>

        <LoadingSection />

        <LoadedDataSection />
      </div>
    </Col>
  );
};

export default MainLayout;
