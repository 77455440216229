/* eslint-disable */
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../context";
import Col from "react-bootstrap/Col";
import LoadedDataSection from "./LoadedDataSection";
import LoadingSection from "./LoadingSection";
import SearchContainer from "./SearchContainer";

const StartPage = () => {
  const { isError, isButtonClicked, handleSearch, setButtonClicked } =
    useGlobalContext();

  return (
    <Col md={12} className="wika-full-page">
      <div className="wika-col-spacing">
        {/* After button was clicked the first time, big search mask is not shown again */}
        {!isError && (
          <SearchContainer
            text={isButtonClicked}
            handleSearch={() => setButtonClicked(false)}
          />
        )}

        <LoadingSection />

        <LoadedDataSection />
      </div>
    </Col>
  );
};

export default StartPage;
