import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../context';

const LoadingSection = () => {
    const { t } = useTranslation();

    const {
        isLoading
    } = useGlobalContext();


    return (
        <div>
            {isLoading && (
                <h4 className="wika-loading-text">
                    {t('app.loading')}...
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </h4>
            )}
        </div>
    );
};

export default LoadingSection;



