/* eslint-disable */
import { useGlobalContext } from "../context";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";
import { useState } from "react";
import TabProductPass from "./TabProductPass";
import TabDownloads from "./TabDownloads";
import TabTestReport from "./TabTestReport";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "./ErrorBoundary";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CustomAccordionToggle from "./CustomAccordionToggle";
import TabProductDocumentation from "./TabProductDocumentation";
import TabCalibration from "./TabCalibration.tsx";

const ProductDataDetails = () => {
  const { productData } = useGlobalContext();
  const passResult = productData.PassResult;
  const { t } = useTranslation();

  const [key, setKey] = useState("productPass");

  return (
    <ErrorBoundary>
      <div id="wika-details-mobile">
        <Accordion flush activeKey={key} onSelect={(k) => setKey(k)}>
          <Card
            className={key === "productPass" ? "wika-accordion-inactive" : ""}
          >
            <Card.Header>
              <CustomAccordionToggle eventKey="productPass">
                <div className="wika-accordion-label">
                  {t("productPass.productDetails")}
                </div>
              </CustomAccordionToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="productPass">
              <Card.Body>
                <ErrorBoundary>
                  <TabProductPass />
                </ErrorBoundary>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            className={
              key === "productdocumentation" ? "wika-accordion-inactive" : ""
            }
          >
            <Card.Header
              className={
                passResult.LinkedDocuments.length === 0
                  ? "wika-accordion-disabled"
                  : ""
              }
            >
              <CustomAccordionToggle eventKey="productdocumentation">
                <div className="wika-accordion-label">
                  {t("productPass.productDocumentation")}
                </div>
              </CustomAccordionToggle>
            </Card.Header>
            {!(passResult.LinkedDocuments.length === 0) && (
              <Accordion.Collapse eventKey="productdocumentation">
                <Card.Body>
                  <ErrorBoundary>
                    <TabProductDocumentation />
                  </ErrorBoundary>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>
          <Card
            className={key === "testReport" ? "wika-accordion-inactive" : ""}
          >
            <Card.Header
              className={
                passResult.CertificatesList.length === 0 &&
                passResult.XMLData.length == 0
                  ? "wika-accordion-disabled"
                  : ""
              }
            >
              <CustomAccordionToggle eventKey="testReport">
                <div className="wika-accordion-label">
                  {t("productPass.testReport")}
                </div>
              </CustomAccordionToggle>
            </Card.Header>
            {!(
              passResult.XMLData.length === 0 &&
              passResult.CertificatesList.length === 0
            ) && (
              <Accordion.Collapse eventKey="testReport">
                <Card.Body>
                  <ErrorBoundary>
                    <TabTestReport />
                  </ErrorBoundary>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>

          <Card
            className={key === "downloads" ? "wika-accordion-inactive" : ""}
          >
            <Card.Header
              className={
                passResult.LinkedWebReferences.length === 0
                  ? "wika-accordion-disabled"
                  : ""
              }
            >
              <CustomAccordionToggle eventKey="downloads">
                <div className="wika-accordion-label">
                  {t("productPass.downloads")}
                </div>
              </CustomAccordionToggle>
            </Card.Header>
            {!(passResult.LinkedWebReferences.length === 0) && (
              <Accordion.Collapse eventKey="downloads">
                <Card.Body>
                  <ErrorBoundary>
                    <TabDownloads />
                  </ErrorBoundary>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>
          <Card
            className={key === "calibration" ? "wika-accordion-inactive" : ""}
          >
            <Card.Header
              className={
                JSON.stringify(passResult.CalibrationHistory) === "{}"
                  ? "wika-accordion-disabled"
                  : ""
              }
            >
              <CustomAccordionToggle eventKey="calibration">
                <div className="wika-accordion-label">
                  {t("productPass.calibration")}
                </div>
              </CustomAccordionToggle>
            </Card.Header>
            {!(JSON.stringify(passResult.CalibrationHistory) === "{}") && (
              <Accordion.Collapse eventKey="calibration">
                <Card.Body>
                  <ErrorBoundary>
                    <TabCalibration />
                  </ErrorBoundary>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>
        </Accordion>
      </div>
      <div id="wika-details-web">
        <Tabs
          id="product-details"
          transition={false}
          activeKey={key}
          onSelect={(k) => setKey(k)}
          defaultActiveKey="productPass"
        >
          <Tab eventKey="productPass" title={t("productPass.productDetails")}>
            <ErrorBoundary>
              <TabProductPass />
            </ErrorBoundary>
          </Tab>
          <Tab
            eventKey="productDocumentation"
            title={t("productPass.productDocumentation")}
            disabled={passResult.LinkedDocuments.length === 0}
          >
            <ErrorBoundary>
              <TabProductDocumentation />
            </ErrorBoundary>
          </Tab>

          <Tab
            eventKey="testReport"
            title={t("productPass.testReport")}
            disabled={
              passResult.XMLData.length === 0 &&
              passResult.CertificatesList.length === 0
            }
          >
            {/* show content if at least one exists */}
            {!(
              passResult.XMLData.length === 0 &&
              passResult.CertificatesList.length === 0
            ) && (
              <ErrorBoundary>
                <TabTestReport />
              </ErrorBoundary>
            )}
          </Tab>
          <Tab
            eventKey="downloads"
            title={t("productPass.downloads")}
            disabled={passResult.LinkedWebReferences.length === 0}
          >
            {!(passResult.LinkedWebReferences.length === 0) && <TabDownloads />}
          </Tab>
          <Tab
            eventKey="calibration"
            title={t("productPass.calibration")}
            disabled={JSON.stringify(passResult.CalibrationHistory) === "{}"}
          >
            {JSON.stringify(passResult.CalibrationHistory) !== "{}" && (
              <TabCalibration />
            )}
          </Tab>
        </Tabs>
      </div>
    </ErrorBoundary>
  );
};

export default ProductDataDetails;
