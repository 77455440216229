/* eslint-disable */
import { useGlobalContext } from "../context";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa";
import { useState } from "react";
import pdfImg from "../images/00005520.jpg";
import { Buffer } from "buffer";

const DatasheetButton = () => {
  const { language, serialNumber, hasDatasheet } = useGlobalContext();
  const [isDatasheetLoaded, setDatasheetLoaded] = useState(true);
  const { t } = useTranslation();

  const [errors, setError] = useState({});
  // todo: change display name
  // todo: test what happens/should happen when nothing is returned/no datasheet exists?
  // todo: show Julian, show Klaus?
  // todo: mobile Ansicht prüfen
  // todo: catch error
  // todo: seriennummer, die datenblatt aht: 1A01ECR4926 auf rc

  // convert base64 to pdf
  const fetchDataSheet = async () => {
    setDatasheetLoaded(false);
    const url = `https://portal.wika.com/serial/srv/serialreq.aspx?obj=getdatasheet&serial=${serialNumber}&reqapp=web.ExtSerial.wika.com&reqver=0.4a&lng=${language}`;
    const resp = await fetch(url);

    if (resp.status >= 200 && resp.status <= 299) {
      const result = await resp.text();
      const decodedPDF = Buffer.from(result, "base64");
      var blob = new Blob([decodedPDF], { type: "application/pdf" });

      const blobUrl = URL.createObjectURL(blob);

      var newWindow = window.open(blobUrl, "_blank");
      newWindow.document.title = "Product Pass Datasheet";
      setDatasheetLoaded(true);

      return blob;
    } else {
      // todo: test if other than success
      setDatasheetLoaded(true);
      let errors = {};
      errors.datasheetBtn = t("app.datasheetButton_val_no");
      setError(errors);
      return "";
    }
    // todo: catch error:
    /*
    setDatasheetLoaded(true);
      let errors = {};
      errors.datasheetBtn = t("app.datasheetButton_val_error");
      setError(errors);
      return "";
    */
  };
  if (isDatasheetLoaded) {
    return (
      <div id="wika-datasheet-btn">
        <button
          disabled={!hasDatasheet}
          className="wika-link"
          onClick={fetchDataSheet}
        >
          <div id="wika-datasheet-btn-error">{errors.datasheetBtn}</div>
          <div className="wika-pdf-div">
            <img src={pdfImg} className="wika-pdf-img" />{" "}
          </div>
          <div className="wika-pdf-spinner-div">
            <span className="wika-pdf-link">{t("app.datasheetButton")}</span>
          </div>
        </button>
      </div>
    );
  } else {
    return (
      <div id="wika-datasheet-btn">
        <button
          disabled={!hasDatasheet}
          className="wika-link"
          onClick={fetchDataSheet}
        >
          <div className="wika-pdf-div">
            <img src={pdfImg} className="wika-pdf-img" />
          </div>
          <div className="wika-pdf-spinner-div">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
            ></span>
            <span className="wika-pdf-link">{t("app.datasheetButton")}</span>
          </div>
        </button>
      </div>
    );
  }
};

export default DatasheetButton;
