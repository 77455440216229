/* eslint-disable */
import { useGlobalContext } from "../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import allLangs from "../all-langs.json";

library.add(faCaretDown);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../images", false, /\.(png|jpe?g|svg)$/)
);

const LanguageDropdown = () => {
  const { language, setLanguage } = useGlobalContext();
  let options = [];

  Object.keys(allLangs).forEach(function (keyName) {
    let option = {
      value: keyName,
      label: (
        <div>
          <img
            src={images[allLangs[keyName].image]}
            style={{ marginRight: "0.5rem" }}
          />
          {allLangs[keyName].label}
        </div>
      ),
    };
    options.push(option);
  });

  const handleClick = (e, value) => {
    e.preventDefault();
    setLanguage(value);
    localStorage.setItem("language", value);
  };

  return (
    <div id="wika-language-dropdown" className="dropdown">
      <button className="dropbtn">
        {options
          .filter((option) => option.value === language)
          .map((filteredLang) => (
            <div key={filteredLang.value} className="wika-dd-wrapper">
              <div className="wika-left-dd">{filteredLang.label}</div>
              <div className="wika-right-dd">
                <FontAwesomeIcon icon="caret-down" />
              </div>
            </div>
          ))}
      </button>
      <div className="wika-dd-lang-content">
        {options.map((data, index) => {
          if (data.value !== language) {
            // selected entry
            return (
              <li
                key={index}
                style={{ listStyle: "none" }}
                onClick={(e) => {
                  handleClick(e, data.value);
                }}
              >
                {
                  <a
                    href="#"
                    className="wika-dd-lang-content-selected"
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    id={data.value}
                  >
                    {" "}
                    {data.label}
                  </a>
                }
              </li>
            );
          }
        })}
      </div>
    </div>
  );
};

export default LanguageDropdown;
