/* eslint-disable */
import { useGlobalContext } from "../context";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorBoundary from "./ErrorBoundary";
import React, { useState } from "react";

const ProductDataHeaderBox = () => {
  const { productData, imgIsUndefined, SetImgUndefined, onImgError } =
    useGlobalContext();
  const passResult = productData.PassResult;
  const src = passResult.PictureUrl.split("//")[1];
  const { t } = useTranslation();

  var manufacturingDate = productData.PassResult["DateManufacturing"];
  //var manufacturingDate = moment(str, "DD-MM-YYYY").format("MM/YYYY");
  const prodDate = manufacturingDate;

  useEffect(() => {
    document.getElementById("typetextsecondline").innerHTML =
      passResult.TypeTextSecondLine;
    // set this so even if error occurs and image is missing, next image will load successfully
    SetImgUndefined(false);
  }, [passResult.TypeTextSecondLine]);

  if (imgIsUndefined) {
    return (
      <>
        <section className="wika-section">
          <ErrorBoundary>
            <Row>
              <ErrorBoundary>
                <Col sm={7} className="wika-product-box">
                  <div className="wika-small-text">
                    {t("productdataHeader.type")} {passResult.TypeText}
                  </div>
                  <h5 id="typetextsecondline"> </h5>
                  <hr />
                  <div className="wika-small-text">
                    {t("productdataHeader.serialNumber")}:{" "}
                  </div>
                  <h4>{productData.PassResult["SerialNumber"]}</h4>
                  <div className="wika-small-text">
                    {t("productdataHeader.itemNumber")}:{" "}
                  </div>
                  <h4>{productData.PassResult["ItemNumber"]}</h4>

                  <div className="wika-small-text">
                    {t("tabProductPass.productionDate")}:{" "}
                  </div>
                  <h4>{prodDate}</h4>
                </Col>
              </ErrorBoundary>
              <ErrorBoundary>
                <Col sm={5} className="card">
                  <i className="fa-solid fa-camera-slash fa-10x"></i>

                  <div className="card-body">
                    <p className="card-text wika-pic-desc">
                      {passResult.PictureDesc}
                    </p>
                  </div>
                </Col>
              </ErrorBoundary>
            </Row>
          </ErrorBoundary>
        </section>
      </>
    );
  }

  return (
    <>
      <section className="wika-section">
        <ErrorBoundary>
          <Row>
            <ErrorBoundary>
              <Col sm={8} className="wika-product-box">
                <div className="wika-small-text">
                  {t("productdataHeader.type")} {passResult.TypeText}
                </div>
                <h5 id="typetextsecondline"> </h5>
                <hr />
                <div className="wika-small-text">
                  {t("productdataHeader.serialNumber")}:{" "}
                </div>
                <h4>{productData.PassResult["SerialNumber"]}</h4>
                <div className="wika-small-text">
                  {t("productdataHeader.itemNumber")}:{" "}
                </div>
                <h4>{productData.PassResult["ItemNumber"]}</h4>

                <div className="wika-small-text">
                  {t("tabProductPass.productionDate")}:{" "}
                </div>
                <h4>{prodDate}</h4>
              </Col>
            </ErrorBoundary>
            <ErrorBoundary>
              <Col sm={4} className="wika-card">
                <img
                  src={`https://portal.wika.com/serial/resource/serial/${src}`}
                  className="card-img-top wika-img-right-col"
                  alt={passResult.TypeText}
                  onError={onImgError}
                />
                <div className="card-body">
                  <p className="card-text wika-pic-desc">
                    {passResult.PictureDesc}
                  </p>
                </div>
              </Col>
            </ErrorBoundary>
          </Row>
        </ErrorBoundary>
      </section>
    </>
  );
};

export default ProductDataHeaderBox;
