/* eslint-disable */
import { useGlobalContext } from "../context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
import DatasheetButton from "./DatasheetButton";

const TabProductPass = () => {
  const { productData } = useGlobalContext();
  const passResult = productData.PassResult;
  const { t } = useTranslation();

  // needed because when innerHTML is used, React has no way to know the DOM node has been modified
  // https://stackoverflow.com/questions/37337289/react-js-set-innerhtml-vs-dangerouslysetinnerhtml

  // mobile and full view are different nodes, so this needs to be added to all of them
  useEffect(() => {
    for (let element of document.getElementsByClassName("itemtext")) {
      element.innerHTML = passResult.ItemText;
    }
  }, [passResult.ItemText]);

  return (
    <div className="wika-tab-content">
      <ErrorBoundary>
        <Row>
          <ErrorBoundary>
            <Col md={6}>
              {passResult.ItemText && (
                <div>
                  <h6>{t("tabProductPass.description")}</h6>
                  <div className="itemtext"></div>
                </div>
              )}
            </Col>
          </ErrorBoundary>
          <ErrorBoundary>
            <Col
              md={6}
              className="wika-datasheet-btn-center"
              style={{ textAlign: "center" }}
            >
              <DatasheetButton />
            </Col>
          </ErrorBoundary>
        </Row>
      </ErrorBoundary>
    </div>
  );
};

export default TabProductPass;
