/* eslint-disable */
import { useGlobalContext } from "../context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HTMLProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import CertificateDropdown from "./CertificateDropdown";


type History = {
  select: string,
  Id: string
  Description: string
  DueDate: string
}



const TabCalibration = (config = null) => {
  const { productData, setLastDownloadUrls, lastDownloadUrls, isUrlLoaded, dropdown, setDropdown } =
    useGlobalContext();
  const { t } = useTranslation();
  const passResult = productData.PassResult;
  const linkedDocuments = passResult.LinkedDocuments;
  const calibrationHistory = passResult.CalibrationHistory;
  const calibrationTool = passResult.CalibrationHistory.CalibrationTool;
  const calibrationOrders = passResult.CalibrationHistory.CalibrationOrders;
  const result : string[] = [];

  const [data, setData] = React.useState(() => [...(calibrationOrders ?? [])]);
  const rerender = React.useReducer(() => ({}), {})[1]
  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  






// todo: remove normal react-table installation

  const columnHelper = createColumnHelper<History>()
  const columns = [
    columnHelper.accessor('select', {
      id: 'select',
      header: () => <div></div>, 
      cell:  ({ row })  => <IndeterminateCheckbox
      {...{
        checked: row.getIsSelected(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
      
    />
    }),
    columnHelper.accessor('Id', {
      id: 'id',
      cell: info => <i>{info.getValue()}</i>,
      header: () => t("tabCalibration.calibrationOrder"), 
    }),
    columnHelper.accessor('Description', {
      id: 'description',
      cell: info => <i>{info.getValue()}</i>,
      header: () => t("tabCalibration.calibrationDesc"), 
    }),
    columnHelper.accessor('DueDate', {
      header: () => t("tabCalibration.calibrationDate"),
      cell: info => info.renderValue(),
    }),
    
  ]


   const rowSelectionChange = (data: Updater<RowSelectionState>): void => {
    if (typeof data === 'function') {
      const newRowSelection = data(rowSelection);
      setRowSelection(newRowSelection); 
      // get part of array by the selected certificate id
      const filteredArray = calibrationOrders.filter((item: History) => item.Id == Object.keys(newRowSelection)[0]);
      // otherwise an error occurs because there are no certificates
      if (filteredArray[0] != undefined)
      {
        setDropdown(filteredArray[0].Certificates);
      }
    }
  };



  // certificate number of order in row and then boolean value -> CW02084528: true
  const table = useReactTable({
    data,
    getRowId(originalRow, index, parent) {
      return parent ? [parent.id, originalRow.Id].join(".") : originalRow.Id;
    },
    columns,
    state: {
      sorting,
      rowSelection,
    },
    
    onSortingChange: setSorting,
    onRowSelectionChange: rowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: false,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    //debugTable: true,
  });



  function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)
  
    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])
  
    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    )
  }


  return (
   <div className="wika-tab-content" >
    {calibrationHistory.length !== 0 && calibrationTool != undefined && (
      <div>
       
       <Row>
        <Col sm={4}>
 
        <h6>{t("tabCalibration.calibrationTool")}</h6>
      
         <table className="table table-borderless">
          <tbody>
              <tr>
                  <td>{t("tabCalibration.description")}:</td>
                  <td>{calibrationTool.Description != "" ? calibrationTool.Description : "-"}</td>
              </tr>
              <tr>
                  <td>{t("tabCalibration.extSerialNum")}:</td>
                  <td>{calibrationTool.RefCustSerialNumber != "" ? calibrationTool.RefCustSerialNumber : "-"}</td>
              </tr>
              <tr>
                  <td>{t("tabCalibration.AssetTagNum")}:</td>
                  <td>{calibrationTool.AssetNumber != "" ? calibrationTool.AssetNumber  : "-"}</td>
              </tr>
            <tr>
                  <td>{t("tabCalibration.DeviceType")}:</td>
                  <td>{calibrationTool.DeviceTypeId != "" ? calibrationTool.DeviceTypeId : "-"}</td>
              </tr>
          </tbody>
        </table>

        <table className="table table-borderless">
          <tbody>
              <tr>
                  <td>{t("tabCalibration.ProductDetails")}:</td>
                  <td>{calibrationTool.ProductDetails ! = "" ? calibrationTool.ProductDetails : "-"}</td>
              </tr>
          </tbody>
        </table> 
     

        <h6>{t("tabCalibration.calibrationHistory")}</h6>
     


        <div>
           <table className="calibration-table-small-screen">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <i className="fa-sharp fa-solid fa-sort-up"></i>,
                              desc: <i className="fa-sharp fa-solid fa-sort-down"></i>,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    )
                          })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          
          </table> 
           </div>
          <br/>
           
           
        </Col>
      </Row>



<Row>
<Col sm={12}>
<h6>{t("tabCalibration.certificates")}</h6>
<CertificateDropdown rowSelectionChange={dropdown}/>
</Col>
</Row>

</div>

     

    )}
    
    </div>
  )
}


export default TabCalibration;
