/* eslint-disable */
import { useGlobalContext } from "../context";
import { HiOutlineMail } from "react-icons/hi";
import React from "react";
import { useTranslation } from "react-i18next";

const MailButton = () => {
  const { t } = useTranslation();
  const { productData, serialNumber } = useGlobalContext();
  const passResult = productData.PassResult;
  const eMailSubject =
    passResult != null ? passResult.eMailSubject.replaceAll("<br>", "") : null;
  const eMailBody =
    passResult != null
      ? passResult.eMailBody.replaceAll("<br>", "%0D%0A")
      : null;
  let mailBodyExtract = eMailBody.slice(0, 1200);

  // if current url contains a serial number use the url otherwise add current serial number
  let url =
    window.location.href.indexOf("?s=") > -1
      ? window.location.href
      : window.location.href + "?s=" + serialNumber;

  // add dots if content is very long, mailto has a length restriction
  let dots = "";
  if (mailBodyExtract.length == 1200) {
    dots = " ...";
  }

  let body =
    t("tabDownloads.emailButtonTextLine1") +
    "%0D%0A %0D%0A" +
    t("tabDownloads.emailButtonTextLine2") +
    "%0D%0A" +
    url +
    "%0D%0A %0D%0A" +
    mailBodyExtract +
    dots;

  return (
    // avoid buttons being displayed in different rows
    <div style={{ display: "contents" }}>
      {(eMailSubject || eMailBody) && (
        <div id="wika-mail-btn" className="wika-button-shadow">
          <a
            href={`mailto:%20?body=${body}&subject=${eMailSubject}`}
            className="wika-button"
          >
            {t("tabDownloads.emailButton")}
          </a>
        </div>
      )}
    </div>
  );
};

export default MailButton;
