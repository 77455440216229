/* eslint-disable */
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="wika-footer-div">
            <div className="wika-footer-box">
                <div className="wika-footer-legal col-md-3">
                    <ul>
                        <li>
                            <a href={t('footer.legalNotice_url', 'https://www.wika.ca/imprint_en_ca.WIKA')} >{t('footer.legalNotice', 'Legal notice')}</a>
                        </li>
                        <li>
                            <a href={t('footer.privacyPolicy_url', 'https://www.wika.ca/privacypolicy_en_ca.WIKA')} >{t('footer.privacyPolicy', 'Privacy Policy')}</a>
                        </li>
                        <li>
                            <a href={t('footer.tandC_url', 'https://www.wika.ca/upload/ZZ_TCs_en_ca_66484.pdf')}>{t('footer.tandC', 'T&Cs')}</a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
            </div>

            <div className="wika-footer">
                © {(new Date().getFullYear())} WIKA Alexander Wiegand SE & Co. KG
            </div>
        </div>
    );
};

export default Footer;



