/* eslint-disable */
import SearchField from "./SearchField";
import { useTranslation } from "react-i18next";
import { React } from "react";

const SearchContainer = (props) => {
  const { t } = useTranslation();
  let element;
  /* if (props.text) {
    element = (
      <div>ON</div>
    );
  } else {
    element = (
      <div>OFF</div>
    ); */
  //}

  return (
    <form className="">
      <h1 className="wika-headline">{t("searchfield.welcome")}</h1>
      <h3 className="mb-3 wika-headline-sub" style={{ textAlign: "center" }}>
        {t("searchfield.instruction")}
      </h3>
      <SearchField handleSearch={props.handleSearch} />
      {/* <div>{element}</div> */}
    </form>
  );
};

export default SearchContainer;
