/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }
  render() {
    const { hasError } = this.state;
    const { t } = this.props;
    if (hasError) {
      return (
        <div className="card my-5">
          <div className="card-body">
            <p>
              {t("errorBoundary.errorOccured")}{" "}
              <span
                style={{ cursor: "pointer", color: "#0077FF" }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t("errorBoundary.pageReload")}
              </span>{" "}
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
export default withTranslation()(ErrorBoundary);
